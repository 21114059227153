require=(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({31:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var verticalFixer = require('../components/vertical-fixer');

var Home = function () {
	function Home() {
		_classCallCheck(this, Home);

		this.buildingContainer = $('.building-container_home');
		this.buildingContainerInner = this.buildingContainer.find('.building-container__inner');
		this.contentContainer = $('#content');

		this.scrollLimit = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);

		Home.MARGIN_VALUE = 150;

		this.init();
		this.attachEvents();

		var that = this;
		var i = 0;
		this.intro = $('.intro');
		this.preloader = $('.preloader');
		this.introImg = this.intro.find('img');

		$(window).on('load', function () {
			// that.intro.animate({
			// 	'-webkit-transform': 'scale(1.5)',
			// 	'-moz-transform': 'scale(1.5)',
			// 	'transform': 'scale(1.5)'
			// }, 3000);
			that.preloader.fadeOut(300, function () {
				that.intro.addClass('scaled');

				var introChanged = setInterval(function () {
					that.introImg.eq(i).fadeOut(300);

					if (i === that.introImg.length - 1) {
						clearInterval(introChanged);

						// $('.building_home').addClass('scaled');
						$('.home-title').addClass('visible');
						$('.home-col').addClass('visible');
						$('.header').addClass('visible');
						$('.footer').addClass('visible');
					}
					i++;
				}, 1000);

				$('.building_home').addClass('scaled');
			});
		});
	}

	_createClass(Home, [{
		key: 'init',
		value: function init() {
			var buildingFixed = new verticalFixer(this.buildingContainer, this.contentContainer, this.contentContainer, this.contentContainer, Home.MARGIN_VALUE);
		}
	}, {
		key: 'attachEvents',
		value: function attachEvents() {
			var that = this;

			$(window).on('scroll', function () {
				var winScroll = $(this).scrollTop();
				var y = winScroll / that.scrollLimit * Home.MARGIN_VALUE;

				that.buildingContainerInner.css({
					'-webkit-transform': 'translateY(-' + y + 'px)',
					'-moz-transform': 'translateY(-' + y + 'px)',
					'transform': 'translateY(-' + y + 'px)'
				});
			});
		}
	}]);

	return Home;
}();

exports.default = Home;

new Home();

},{"../components/vertical-fixer":25}],25:[function(require,module,exports){
'use strict';

//
// Copyright 2012 Art. Lebedev Studio. All Rights Reserved.
// Created on 2012.03.26
//
// This source code follows Google JavaScript Style Guide
// http://google-styleguide.googlecode.com/svn/trunk/javascriptguide.xml
//

/**
 * @fileoverview  Class responsible for visual fixing html block on page scroll.
 *
 * @author Alexander Samilyak (aleksam241@gmail.com)
 */

// @require als.js.
// var als = als || {};
var als = als ? als : {};

/*
 Replace this with:
   goog.provide('als.VerticalFixer');
   goog.require('als');

 if your project framework is Closure Library.
*/

/**
 * @param {!jQuery} root
 * @param {!jQuery} topLimiter  Must be root's closest positioned ancestor.
 * @param {!jQuery} bottomLimiter
 * @param {!jQuery=} opt_widthLikeThat
 * @param {number=} opt_topMargin  Vertical pixel margin between root's top and
 * browser's viewport top to preserve when fixing block.
 * @constructor
 */
als.VerticalFixer = function (root, topLimiter, bottomLimiter, opt_widthLikeThat, opt_topMargin) {

  /**
   * @type {!jQuery}
   * @private
   */
  this.root_ = root;

  /**
   * @type {!jQuery}
   * @private
   */
  this.topLimiter_ = topLimiter;

  /**
   * @type {!jQuery}
   * @private
   */
  this.bottomLimiter_ = bottomLimiter;

  /**
   * @type {jQuery}
   * @private
   */
  this.widthLikeThat_ = opt_widthLikeThat || null;

  /**
   * @type {number}
   * @private
   */
  this.topMargin_ = opt_topMargin === undefined ? als.VerticalFixer.DEFAULT_TOP_MARGIN : opt_topMargin;

  /**
   * @type {!jQuery}
   * @private
   */
  this.window_ = jQuery(window);

  /**
   * @type {boolean}
   * @private
   */
  this.enabled_ = true;

  /**
   * @type {?als.VerticalFixer.PositionStatus}
   * @private
   */
  this.currentStatus_ = null;

  this.refresh();
  this.attachEvents_();
};

/**
 * @const
 * @type {number}
 */
als.VerticalFixer.DEFAULT_TOP_MARGIN = 15;

/**
 * @const
 * @type {string}
 */
als.VerticalFixer.STOPPED_CLASS = 'stopped';

/**
 * @typedef {{
 *   status: als.VerticalFixer.PositionStatus,
 *   top: number
 * }}
 */
als.VerticalFixer.Position = {};

/**
 * @enum {number}
 */
als.VerticalFixer.PositionStatus = {
  NOT_STOPPED: 0,
  STOPPED_ON_TOP: 1,
  STOPPED_ON_BOTTOM: 2
};

als.VerticalFixer.prototype.refresh = function () {
  this.refreshLeft_();
  this.refreshWidth_();
  this.refreshPosition_(true);
};

als.VerticalFixer.prototype.enable = function () {
  this.enabled_ = true;
  this.refresh();
};

als.VerticalFixer.prototype.disable = function () {
  this.enabled_ = false;
  this.root_.css({ 'left': '', 'top': '', 'width': '' }).removeClass(als.VerticalFixer.STOPPED_CLASS);
};

/**
 * @private
 */
als.VerticalFixer.prototype.attachEvents_ = function () {
  var that = this;

  this.window_.resize(function () {
    if (that.enabled_) {
      that.refresh();
    }
  }).scroll(function () {
    if (that.enabled_) {
      that.refreshPosition_();
    }
  });
};

/**
 * @param {boolean=} opt_force
 * @private
 */
als.VerticalFixer.prototype.refreshPosition_ = function (opt_force) {
  /** @type {!als.VerticalFixer.Position} */
  var position = this.getPosition_();

  if (position.status === this.currentStatus_ && !opt_force) {
    return;
  }

  this.currentStatus_ = position.status;

  /** @type {boolean} */
  var stopped = position.status !== als.VerticalFixer.PositionStatus.NOT_STOPPED;

  this.refreshLeft_();
  this.root_.toggleClass(als.VerticalFixer.STOPPED_CLASS, stopped);
  this.root_.css('top', stopped ? position.top - this.topLimiter_.offset().top : this.topMargin_);
};

/**
 * @private
 */
als.VerticalFixer.prototype.refreshLeft_ = function () {
  if (this.currentStatus_ === als.VerticalFixer.PositionStatus.NOT_STOPPED) {

    this.root_.css('left', this.topLimiter_.offset().left);
  } else {
    this.root_.css('left', '');
  }
};

/**
 * @private
 */
als.VerticalFixer.prototype.refreshWidth_ = function () {
  if (this.widthLikeThat_) {
    this.root_.width(this.getBlockWidth_(this.widthLikeThat_));
  }
};

/**
 * @private
 */
als.VerticalFixer.prototype.getBlockWidth_ = function (block) {
  return (/** @type {number} */block.width()
  );
};

/**
 * @return {!als.VerticalFixer.Position}
 * @private
 */
als.VerticalFixer.prototype.getPosition_ = function () {
  /** @type {{ top: number, bottom: number }} */
  var bounds = this.getBounds_();

  /** @type {number} */
  var freeTop = /** @type {number} */this.window_.scrollTop() + this.topMargin_;

  /** @type {number} */
  var height = this.root_.innerHeight();

  /** @type {number} */
  var boundsHeight = bounds.bottom - bounds.top;

  if (freeTop < bounds.top || height > boundsHeight) {
    return {
      status: als.VerticalFixer.PositionStatus.STOPPED_ON_TOP,
      top: bounds.top
    };
  } else {
    if (freeTop + height > bounds.bottom) {
      return {
        status: als.VerticalFixer.PositionStatus.STOPPED_ON_BOTTOM,
        top: bounds.bottom - height
      };
    } else {
      return {
        status: als.VerticalFixer.PositionStatus.NOT_STOPPED,
        top: 0
      };
    }
  }
};

/**
 * @return {{ top: number, bottom: number }}
 * @private
 */
als.VerticalFixer.prototype.getBounds_ = function () {
  return {
    top: this.topLimiter_.offset().top,
    bottom: this.bottomLimiter_.offset().top + this.bottomLimiter_.innerHeight()
  };
};

module.exports = als.VerticalFixer;

},{}]},{},[31]);
